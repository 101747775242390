/*
 * 数据集成--路由配置
 */

// 数据集成
const dataIntegration = () => import('@/pages/dataIntegration/index.vue');
// 数据表管理
const tableMgn = () => import('@/pages/tableMgn/index.vue');

export default [
  // 数据集成概览
  {
    path: '/dataIntegration',
    name: 'dataIntegration',
    component: dataIntegration,
  },
  // 数据表管理
  {
    path: '/tableMgn',
    name: 'tableMgn',
    component: tableMgn,
  },
];
